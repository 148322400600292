import { defineStore } from 'pinia';
import axios from 'axios';
import { LRUCache } from 'lru-cache';

// Configuração do cache LRU
const cache = new LRUCache({
  max: 50,
  maxAge: 1000 * 60 * 5, // Cache expira após 5 minutos
});

export const usePacotesStore = defineStore('pacotes', {
  state: () => ({
    portfolios: [], // Dados de todos os portfolios
    pacotesPromocionais: [], // Pacotes promocionais a serem exibidos
    imagensCarregadas: [], // Status de carregamento das imagens
    dialogImagem: false, // Controle do diálogo de visualização de imagens
    currentImage: 0, // Índice da imagem atualmente exibida no diálogo
    imagensDialog: [] // Lista de imagens exibidas no diálogo
  }),

  actions: {
    /**
     * Carrega os portfolios e preenche os pacotes promocionais.
     */
    async carregarPortfolios() {
      const cacheKey = 'pacotesPromocionais';
      try {
        if (cache.has(cacheKey)) {
          // Recuperar dados do cache
          this.pacotesPromocionais = cache.get(cacheKey);
        } else {
          // Fazer requisição ao servidor
          const response = await axios.get('https://prev-construtora-bff6fa01a3b5.herokuapp.com/portfolios');
          this.portfolios = response.data;

          // Processar dados para pacotes promocionais
          this.pacotesPromocionais = this.portfolios.slice(0, 3).map((portfolio, index) => ({
            ...portfolio,
            titulo: ["Pacote Básico", "Pacote Standard", "Pacote Premium"][index],
            descricoes: portfolio.titulos
              ? portfolio.titulos.flatMap(titulo => titulo.descricoes)
              : []
          }));

          // Armazenar dados no cache
          cache.set(cacheKey, this.pacotesPromocionais);
        }

        // Pré-carregar imagens
        this.preCarregarImagens();
      } catch (error) {
        console.error('Erro ao carregar portfolios:', error.message);
      }
    },

    /**
     * Retorna a URL completa de uma imagem.
     */
    obterUrlImagem(img) {
      return img.startsWith("https://")
        ? img
        : `https://para-construcoes.s3.us-west-2.amazonaws.com/${img}`;
    },

    /**
     * Abre o diálogo de visualização de imagens.
     */
    abrirImagem(pacoteIdx, imgIdx) {
      const pacote = this.pacotesPromocionais[pacoteIdx];
      this.imagensDialog = pacote.imagensServicos.map(img => this.obterUrlImagem(img));
      this.currentImage = imgIdx;
      this.dialogImagem = true;
    },

    /**
     * Fecha o diálogo de visualização de imagens.
     */
    fecharDialogImagem() {
      this.dialogImagem = false;
      this.imagensDialog = [];
      this.currentImage = 0;
    },

    /**
     * Pré-carrega as imagens dos pacotes promocionais.
     */
    preCarregarImagens() {
      this.imagensCarregadas = this.pacotesPromocionais.map(() => false);

      this.pacotesPromocionais.forEach((pacote, index) => {
        Promise.all(
          pacote.imagensServicos.map(img => {
            return new Promise(resolve => {
              const imgElement = new Image();
              imgElement.src = this.obterUrlImagem(img);
              imgElement.onload = resolve;
            });
          })
        ).then(() => {
          this.imagensCarregadas[index] = true;
        });
      });
    }
  }
});
