<template>
  <v-card outlined class="pa-4 mt-n2 hover-card" :height="625">
    <div class="text-h4 mb-2 title-highlight">FAQ - Perguntas Frequentes</div>
    <p class="text-center mb-4">
      Compilamos uma lista de perguntas frequentes sobre orçamentos, projetos e nossa abordagem de trabalho. Esperamos que essas respostas ajudem a esclarecer suas dúvidas e a tomar decisões informadas.
    </p>
    <v-expansion-panels class="font-weight-bold mb-4">
      <v-expansion-panel
        title="Como solicitar um orçamento?"
        text="Preencha nosso formulário de contato com detalhes do projeto. Entraremos em contato para discutir mais detalhes e elaborar um orçamento personalizado."
      ></v-expansion-panel>
      
      <v-expansion-panel
        title="Quanto tempo para receber o orçamento?"
        text="Nosso objetivo é fornecer um orçamento inicial dentro de 48 horas após receber sua solicitação e todas as informações necessárias."
      ></v-expansion-panel>

      <v-expansion-panel
        title="Posso alterar o projeto após o orçamento?"
        text="Sim, estamos abertos a discutir e ajustar o projeto conforme suas necessidades."
      ></v-expansion-panel>

      <v-expansion-panel
        title="Como garantem a qualidade do trabalho?"
        text="Usamos materiais de alta qualidade, empregamos uma equipe qualificada e seguimos as melhores práticas da indústria."
      ></v-expansion-panel>

      <v-expansion-panel
        title="Oferecem pacotes de serviços ou descontos?"
        text="Sim, oferecemos pacotes de serviços que podem incluir construção, reforma e manutenção, muitas vezes com descontos competitivos."
      ></v-expansion-panel>

      <v-expansion-panel
        title="Atendem a projetos comerciais e residenciais?"
        text="Sim, atendemos a projetos residenciais e comerciais, adaptando nossos serviços às necessidades específicas de cada cliente."
      ></v-expansion-panel>
    </v-expansion-panels>
    <p class="text-center pa-2 mt-n2">
      Esta seção de FAQ visa fornecer informações claras e diretas sobre o processo de orçamento, nossa abordagem e como atendemos às suas necessidades.
    </p>
  </v-card>
</template>
<style scoped>
.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mt-6 {
  margin-top: 48px !important;
}

.title-highlight {
  text-align: center;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
.text-center {
  font-size: 14px;
  color: #212121;
  line-height: 1.4;
 
}

.font-weight-bold {
  font-weight: bold;
}

.v-expansion-panel-header {
  background-color: #f5f5f5; /* Cor de fundo dos cabeçalhos das perguntas */
  cursor: pointer;
}

.v-expansion-panel-content {
  background-color: #ffffff; /* Cor de fundo do conteúdo das perguntas */
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87) /* Cor branca */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}
</style>
