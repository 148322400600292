<template>
  <!-- Componente estático 1 -->
  <transition name="fade" mode="out-in">
    <PacotesPromo v-if="showStaticComponents" class="pacotes-promo pa-2" />
  </transition>

  <!-- Componente estático 2 -->  <transition name="fade" mode="out-in">
    <SlideDepoimentos v-if="showStaticComponents" class="mt-n8 slide-depoimentos" />
  </transition>
  

  <!-- Componente dinâmico 1 -->
  <transition name="fade" mode="out-in">
    <VamosConversar v-if="showDynamicComponents" class="vamos-conversar" />
  </transition>


  <!-- Componente dinâmico 2 -->
  <transition name="fade" mode="out-in">
    <ProcessoOrcamento v-if="showDynamicComponents" class="processo-orcamento" />
  </transition>
  
</template>



<script>
import SlideDepoimentos from '@/components/Home/slideDepoimentos.vue';
import PacotesPromo from '@/components/Home/PacotesPromo.vue';
import VamosConversar from '@/components/Home/VamosConversar.vue';
import ProcessoOrcamento from '@/components/Home/ProcessoOrcamento.vue';

export default {
  data() {
    return {
      showStaticComponents: false, // Controle dos componentes estáticos
      showDynamicComponents: false, // Controle dos componentes dinâmicos
    };
  },
  metaInfo() {
    return {
      title: "JE Construtora - Soluções Inteligentes em Construção Civil",
      meta: [
        {
          name: "description",
          content:
            "Bem-vindo à JE Construtora. Oferecemos serviços de construção civil, reformas e orçamentos personalizados para tornar seus projetos realidade.",
        },
        {
          name: "keywords",
          content: "construção civil, reformas, orçamentos, engenharia, construtora",
        },
      ],
    };
  },
  components: {
    SlideDepoimentos,
    PacotesPromo,
    ProcessoOrcamento,
    VamosConversar,
  },
  mounted() {
    // Exibe os componentes estáticos primeiro
    this.showStaticComponents = true;

    // Aguarda 1s antes de exibir os componentes dinâmicos
    setTimeout(() => {
      this.showDynamicComponents = true;
    }, 1000);
  },
};
</script>


<style scoped>

.pacotes-promo {
  margin: 40px 0 0 0;
  z-index: 0;
  position: relative;
}

.slide-depoimentos {
  padding: 20px;
  margin: 0 0 0 0;
  background-color: #f5f5f5;
  z-index: 2;
  position: relative;
}

.processo-orcamento {
  margin: 0 0 0 0;
  padding: 15px;
  background-color: #fafafa;
  z-index: 3;
  position: relative;
}

.vamos-conversar {
  margin: 0 0 0 0;
  padding: 10px;
  background-color: #e0e0e0;
  z-index: 1;
  position: relative;
}
/* Transições suaves */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
