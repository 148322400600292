<template>
  <v-row>
    <!-- Itera sobre os pacotes promocionais -->
    <v-col
      cols="12"
      md="4"
      v-for="(pacote, pacoteIdx) in pacotesStore.pacotesPromocionais"
      :key="pacote._id"
    >
      <v-card class="pa-4 hover-card">
        <p class="title-highlight">{{ pacote.titulo }}</p>

        <!-- Skeleton Loader para imagens -->
        <v-skeleton-loader
          v-if="!pacotesStore.imagensCarregadas[pacoteIdx]"
          type="image"
        ></v-skeleton-loader>

        <!-- Carrossel de imagens -->
        <v-carousel
          v-else
          cycle
          height="300"
          :continuous="true"
          :show-arrows="false"
          delimiter-icon="mdi-square"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(img, idx) in pacote.imagensServicos"
            :key="idx"
          >
          <div>
            <v-img
              :src="pacotesStore.obterUrlImagem(img)"
              contain
              :alt="`Imagem ${idx + 1}`"
              class="carousel-image"
              @click="pacotesStore.abrirImagem(pacoteIdx, idx)"
            ></v-img>
          </div>  
          
          </v-carousel-item>
        </v-carousel>
	<v-carousel class="description-text" v-if="pacote.descricoes.length" cycle height="20" :continuous="true" :show-arrows="false" hide-delimiters>
          <v-carousel-item v-for="(desc, descIdx) in pacote.descricoes" :key="descIdx">
            {{ desc }}
          </v-carousel-item>
        </v-carousel>
      <v-card-actions class="mt-4">
          <v-btn 
            class="rounded-lg custom-btn w-100 mt-n12" 
            @click="abrirWhats"
            aria-label="Abrir WhatsApp para orçamento"
          >
            <v-icon left>mdi-whatsapp</v-icon>
            Orçamento Online
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-col>

    <!-- Diálogo para exibir imagens ampliadas -->
    <v-dialog v-model="pacotesStore.dialogImagem" max-width="800px">
      <v-carousel
        v-if="pacotesStore.imagensDialog.length"
        v-model="pacotesStore.currentImage"
        height="auto"
        show-arrows
        delimiter-icon="mdi-square"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(img, idx) in pacotesStore.imagensDialog"
          :key="idx"
        >
          <v-img :src="img" alt="Imagem Ampliada" contain>
            <v-btn
              icon="mdi-close"
              @click="pacotesStore.fecharDialogImagem"
            ></v-btn>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-row>
</template>
<script>
import { usePacotesStore } from "@/estados/PortfolioEstado";
import { onMounted } from "vue";

export default {
  setup() {
    const pacotesStore = usePacotesStore();

    // Carrega os portfolios ao montar o componente
    onMounted(() => {
      pacotesStore.carregarPortfolios();
    });

    const abrirWhats = () => {
      window.open(
        "https://wa.me/5511972468550?text=Ol%C3%A1,%20gostaria%20de%20um%20or%C3%A7amento!",
        "_blank"
      );
    };

    return {
      pacotesStore,
      abrirWhats,
    };
  },
};
</script>


<style scoped>
.carousel-image {
  width: 100%;              /* Preenche a largura do contêiner */
  height: 100%;             /* Preenche a altura do contêiner */
  object-fit: cover;        /* Garante corte para preencher todo o espaço */
  object-position: center;  /* Centraliza a imagem no contêiner */
  display: block;           /* Evita espaços extras ao redor da imagem */
}

.hover-card {
  margin-top: -25px;
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(214, 214, 214, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
}
.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.2);
}
.custom-btn {
  background-color: rgba(211, 47, 47, 0.9) !important; /* Vermelho mais vivo com 90% de opacidade */
  color: white !important;
  font-weight: bold;
}

.custom-btn:hover {
  background-color: rgba(198, 40, 40, 0.9) !important; /* Vermelho mais intenso com 90% de opacidade */
}

/* Estilo do título com destaque */
.title-highlight {
  text-align: center;
  color: #9b0303dc; /* Vermelho Escuro */
  font-size: 16px;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para profundidade */
  letter-spacing: 1px; /* Aumenta o espaçamento entre as letras para melhorar a legibilidade */
}

/* Ajuste de estilo do texto das descrições */
.description-text {
  text-align: center;
  margin: -20px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.637)    /* Vermelho Escuro com 90% de opacidade */
  );
  color:#9b0303dc;
}

/* Placeholder de carregamento */
.v-skeleton-loader {
  background: #e0e0e0;
  border-radius: 8px;
  height: 100%;
}

/* Estilos do diálogo de imagem */
.v-dialog .v-img {
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

/* Melhorando o layout para telas pequenas */
@media (max-width: 300px) {
  .hover-card {
    height: auto;
  }
  .v-card-title {
    font-size: 18px;
  }
  .carousel-image {
    height: 200px; /* Ajuste da altura da imagem no carousel */
  }
}
</style>
