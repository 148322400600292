<template>
    <v-card>
      <v-card-title class="text-center">Preencha o Formulário de Contato</v-card-title>
      <v-form class="pa-2">
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field label="Seu Nome" v-model="dadosCadastrais.nomeUser"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Telefone" v-model="dadosCadastrais.telefoneUser"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-text-field label="E-mail" v-model="dadosCadastrais.emailUser"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-text-field label="Motivo do Contato" v-model="dadosCadastrais.motivoUser"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-text-field label="Descrição Assunto" v-model="dadosCadastrais.descrUser"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-n6">
        <v-btn @click="closeDialog">Cancelar</v-btn>
        <v-btn @click="coletarUser">Solicitar Contato</v-btn>
      </v-card-actions>
    </v-card>
  </template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      dadosCadastrais: {
        nomeUser: '',
        emailUser: '',
        telefoneUser: '',
        motivoUser: '',
        descrUser: '',
      },
    };
  },

  methods: {
    async coletarUser() {
      try {
        await axios.post('https://prev-construtora-bff6fa01a3b5.herokuapp.com/contatos', { ...this.dadosCadastrais });
        alert('Solicitação Realizada, Aguarde nosso Contato!');
        this.limparVariaveisCadastro(); // Limpar os campos após submissão bem-sucedida
      } catch (error) {
        console.error('Não conseguimos Realizar sua Solicitação!', error.message);
      }
    },

    limparVariaveisCadastro() {
      this.dadosCadastrais = {
        nomeUser: '',
        emailUser: '',
        telefoneUser: '',
        motivoUser: '',
        descrUser: '',
      };
    },

    closeDialog() {
      this.limparVariaveisCadastro(); // Limpar os campos ao cancelar
      this.dialogContato = false;
    },
  },
};
</script>
<style scoped>
.custom-btn {
  background-color: rgba(211, 47, 47, 0.9) !important; /* Vermelho mais vivo com 90% de opacidade */
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.custom-btn:hover {
  background-color: rgba(198, 40, 40, 0.9) !important; /* Vermelho mais intenso com 90% de opacidade */
  transform: scale(1.05);
}
</style>
  