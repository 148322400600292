<template>
  <v-card class="pa-4 mt-2 hover-card" outlined>
    <v-row>
      <!-- Título Principal -->
      <v-col cols="12">
        <div class="text-h4 text-center mb-4 styled-title">
          Compromisso e Qualidade
        </div>
        <p class="text-justify mb-4">
          Na JE CONSTRUTORA, nosso compromisso com a excelência e a qualidade é o alicerce de tudo o que fazemos. Desde a nossa fundação, dedicamos-nos a oferecer serviços de construção e reforma que superem as expectativas de nossos clientes. Nossa abordagem centrada no cliente nos tornou uma referência confiável no setor.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <!-- Texto Sobre Compromisso e Qualidade -->
      <v-col cols="12" md="6">
        <p class="text-justify mb-2">Nosso compromisso é inabalável. Prometemos:</p>
        <ul class="styled-list">
          <li class="text-justify mb-4">
            <span class="font-weight-bold">Qualidade Impecável:</span> Cada projeto é executado com a mais alta qualidade, atendendo aos rigorosos padrões da indústria.
          </li>
          <li class="text-justify mb-4">
            <span class="font-weight-bold">Transparência e Integridade:</span> Mantemos uma comunicação aberta e honesta em todas as etapas.
          </li>
          <li class="text-justify mb-4">
            <span class="font-weight-bold">Cumprimento de Prazos:</span> Cumprimos rigorosamente os prazos acordados.
          </li>
          <li class="text-justify mb-4">
            <span class="font-weight-bold">Satisfação do Cliente:</span> Sua satisfação é nosso maior objetivo.
          </li>
        </ul>
        <div class="text-h6 mb-2 text-primary">Qualidade em Cada Detalhe</div>
        <p class="text-justify mb-4">
          A qualidade está nos detalhes. Da escolha dos materiais à mão de obra especializada, garantimos que cada aspecto seja planejado e executado para durabilidade e beleza.
        </p>
      </v-col>

      <!-- Slide de Qualidade -->
      <v-col cols="12" md="6" class="mb-4">
        <div v-if="ultimoslideQ.imagensSlideq.length">
          <v-carousel class="mt-n4 pa-2 carousel-image" cycle height="400" hide-delimiters :show-arrows="false">
            <v-carousel-item v-for="(img, i) in ultimoslideQ.imagensSlideq" :key="i">
              <div>
                <v-img
                :src="obterUrlImagem(img)"
                contain
                class="carousel-image"
                :alt="`Imagem ${i + 1}`"
              ></v-img>
              </div>
              
            </v-carousel-item>
          </v-carousel>
        </div>
        <div v-else>
          <v-skeleton-loader class="full-width" height="400px" type="image" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      ultimoslideQ: {
        imagensSlideq: [] // Inicialização para evitar erros
      },
    };
  },

  mounted() {
    this.carregarultimoslideQ();
  },

  methods: {
    async carregarultimoslideQ() {
      try {
        const response = await axios.get("https://prev-construtora-bff6fa01a3b5.herokuapp.com/slidesQ");
        if (response.data && response.data.length > 0) {
          this.ultimoslideQ = response.data[response.data.length - 1];
        }
      } catch (error) {
        console.error("Erro ao carregar o último slide:", error.message);
      }
    },

    obterUrlImagem(img) {
      if (typeof img === 'string' && img.startsWith("https://")) {
        return img;
      }
      // Caso a imagem não seja uma URL completa, construímos a URL base
      return img ? `https://para-construcoes.s3.us-west-2.amazonaws.com/${img}` : '';
    },
  }
};
</script>

<style scoped>
/* Animações e Gradientes */
.styled-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.text-justify {
  color: #212121;
  line-height: 1.6;
  font-size: 16px;
}
.styled-card {
  background: linear-gradient(145deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.styled-list li {

  list-style-type: none;
  padding-left: 1.2em;
  position: relative;
}

.styled-list li:before {
  content: "•";
  color: #b71c1cad; /* Azul */
  font-weight: bold;
  position: absolute;
  left: 0;
}

.text-primary {
  color: #b71c1cad; /* Azul primário */
}

/* Carousel e imagens */
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.full-width {
  width: 100%;
}
</style>
