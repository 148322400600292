<template>
  <v-card class="pa-6 hover-card" outlined>
    <!-- Título principal -->
    <v-card-title class="mb-4 styled-title">Processo de Orçamento</v-card-title>
    <v-card-subtitle class="text-j mb-12 mt-n2">Nesta seção, queremos esclarecer como a JE CONSTRUTORA elabora orçamentos para garantir que os clientes compreendam nosso processo transparente e eficaz.</v-card-subtitle>

    <!-- Etapas do processo -->
    <v-row>
      <v-col v-for="(item, index) in passosOrcamento" :key="index" cols="12" md="4" class="mb-3 hover-card">
        <!-- Cartão de cada etapa -->
        <v-card-title class="title-highlight">
            <v-icon left color="red-darken-4">mdi-check-circle</v-icon> {{ index + 1 }}. {{ item.titulo }} <span></span>
          </v-card-title>
          <!-- Descrição do passo -->
          <v-card-text class="pa-2 mt-n2">{{ item.descricao }}</v-card-text>
      </v-col>
    </v-row>
    <v-card-subtitle class="text-j mb-12 mt-n2">Na JE CONSTRUTORA, acreditamos que um orçamento personalizado é o primeiro passo para um projeto bem-sucedido. Estamos ansiosos para trabalhar com você!</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      passosOrcamento: [
        { titulo: 'Coleta de Informações', descricao: 'Antes de criar qualquer orçamento, nossa equipe dedicada realiza uma coleta minuciosa de informações sobre seu projeto.' },
        { titulo: 'Avaliação e Análise', descricao: 'Nossa equipe realiza uma análise cuidadosa e detalhada.' },
        { titulo: 'Consulta com Clientes', descricao: 'Valorizamos o envolvimento dos clientes em todo o processo.' },
        { titulo: 'Transparência e Detalhamento', descricao: 'Nossa política é ser transparente com nossos clientes.' },
        { titulo: 'Opções e Flexibilidade', descricao: 'Inclui opções e flexibilidade para acomodar mudanças.' },
        { titulo: 'Entrega Oportuna', descricao: 'Comprometemo-nos a entregar o orçamento de forma oportuna.' },
        { titulo: 'Esclarecimento de Dúvidas', descricao: 'Estamos à disposição para esclarecer qualquer dúvida.' },
        { titulo: 'Aceitação do Orçamento', descricao: 'Formalizamos a aceitação com o cliente.' },
        { titulo: 'Garantia de Precisão', descricao: 'Fornecemos orçamentos precisos que refletem fielmente os custos do projeto.' },
      ],
    };
  },
};
</script>

<style scoped>

.text-justify {
  color: #212121;
  line-height: 1.6;
  font-size: 12px;
  text-align: center;
}

.title-highlight {
  color: #9b0303dc; /* Vermelho Escuro */
  font-size: 16px;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.v-card-text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  line-height: 1.4;
  padding: 10px 0;
}

.v-icon {
  font-size: 16px;
  color: #770901c0; /* Ícone vermelho escuro */
}

.styled-title {
  text-align: center;
  font-size: 18px;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Estilos para os cartões de passos */
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(to right, rgba(192, 190, 190, 0.747), rgba(255, 255, 255, 0.87));
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

/* Ajustes para telas pequenas */
@media (max-width: 300px) {
  .styled-title {
    font-size: 22px;
  }
  .v-card-text {
    font-size: 12px;
  }

  .v-icon {
    font-size: 10px;
  }
}
</style>
