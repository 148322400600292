<template>
  <v-parallax height="150" class="hover-card" src="@/assets/Home.webp" alt="Imagem de Construção ou Reforma">
    <div class="d-flex flex-column fill-height align-center parallax-content">
      <h1 class="mt-4 styled-title">Vamos conversar sobre sua Construção ou Reforma?</h1>
      <v-btn
        @click="abrirWhats"
        class="custom-btn"
        >Fale com Um Especialista</v-btn>
    </div>
  </v-parallax>
</template>

<script>
export default {
  name: 'ParallaxSection',
  methods: {
    abrirWhats() {
      window.open('https://api.whatsapp.com/message/WDVGHRI54LBYF1?autoload=1&app_absent=0', '_blank');
    }
  }
};
</script>

<style scoped>
.styled-title {
  text-align: center;
  color: #ffffff; /* Branco puro para maior contraste */
  font-size: 20px; /* Tamanho um pouco maior para destaque */
  font-weight: 700; /* Fonte mais grossa para maior visibilidade */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* Sombra mais intensa */
  letter-spacing: 1.5px; /* Mais espaçamento entre as letras */
}

.custom-btn {
  background-color: rgba(211, 47, 47, 0.9) !important; /* Vermelho mais vivo com 90% de opacidade */
  color: white !important;
  font-weight: bold;
}
.custom-btn:hover {
  background-color: #c62828 !important; /* Vermelho mais intenso no hover */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Adiciona sombra ao passar o mouse */
}
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(to right, rgba(236, 236, 236, 0.9), rgba(255, 255, 255, 0.87));
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}
.parallax-content {
  background-color: rgba(0, 0, 0, 0.4); /* Fundo mais opaco para destacar os elementos */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
}

.v-parallax__image-container img {
  object-fit: cover; /* Preenche o espaço */
  opacity: 0.15; /* Deixa a imagem mais opaca */
  filter: brightness(0.8); /* Reduz o brilho para evitar distração */
}


/* Responsividade para telas pequenas */
@media (max-width: 600px) {
  .parallax-content h1 {
    font-size: 1.2rem;
  }
  .parallax-content {
    padding: 10px;
  }
}
</style>
