<template>
    <v-dialog
        v-model="dialogOrcamento"
        width=800px
        transition="dialog-bottom-transition"
        @click:outside="closeDialog"
    >
    <template v-slot:activator="{props}">
    <v-btn block class="ml-12 rounded-e-lg custom-btn"
    v-bind="props"
    @click:outside="closeDialog"
    >
    Orçamento Aqui!
    </v-btn>
    </template>

        <v-card>
        <v-card-title>Informações para Orçamento</v-card-title>
        <v-form>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Seu nome" v-model="dadosCadastrais.nomeCliente"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Contato" v-model="dadosCadastrais.contatoCliente"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Endereço" v-model="dadosCadastrais.enderecoCliente"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Categoria" v-model="dadosCadastrais.categoriaOrcamento"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Material Incluso ?" v-model="dadosCadastrais.materialIncluso"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                
        </v-form>
        
            <v-card-actions>
                <v-btn @click="dialogOrcamento = false">Cancelar</v-btn>
                <v-btn @click="coletarOrcamento">Solicitar Orçamento</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios';

    export default{
        data(){
            return{
                dialogOrcamento: false,

                dadosCadastrais: {
                    nomeCliente: '',
                    contatoCliente: '',
                    enderecoCliente: '',
                    categoriaOrcamento:'',
                    materialIncluso:'',
                },
            }
        },

        watch:{
            dialogOrcamento(newVal){
                if(newVal){
                    this.limparVariaveisCadastro();
                }
            },
        },

        methods:{
            async coletarOrcamento(){
                try{
                    await axios.post('https://prev-construtora-bff6fa01a3b5.herokuapp.com/orcamentos', {...this.dadosCadastrais});
                    alert('Solicitação Realizada, Aguarde nosso Contato !');
                    this.dialogOrcamento = false;
                } catch (error){
                    console.error('Não conseguimos Realizar seu Orçamento ! '. error.message);
                }
            },

            limparVariaveisCadastro(){
                this.dadosCadastrais = {
                    nomeCliente: '',
                    contatoCliente:'',
                    enderecoCliente:'',
                    categoriaOrcamento:'',
                    materialIncluso:'',
                };
            },

            closeDialog(){
                this.dialogOrcamento = false;
                this.$emit('closeDrawer');
            },
        },
    }

</script>

<style scoped>
.custom-btn {
  background-color: rgba(211, 47, 47, 0.9) !important; /* Vermelho mais vivo com 90% de opacidade */
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  height: 200px;
  width: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

}

.custom-btn:hover {
  background-color: rgba(198, 40, 40, 0.9) !important; /* Vermelho mais intenso com 90% de opacidade */
  transform: scale(1.05);
}
</style>