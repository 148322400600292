<template>
  <v-app-bar
    app
    class="px-4 custom-app-bar"
  >
    <v-row align="center" class="w-100">
      <!-- Logo e Link -->
      <v-col cols="2" xs="4" class="d-flex align-center">
        <router-link to="/" class="router-link-no-underline">
          <v-avatar size="52px" rounded="0">
            <v-img cover src="../assets/LOGO.png" alt="Logo"></v-img>
          </v-avatar>
        </router-link>
      </v-col>

      <!-- Botão de Orçamento -->
      <v-col cols="8" xs="6" class="d-flex justify-center">
       <formOrcamento/>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <!-- Menu para telas maiores -->
    <div class="d-none d-lg-flex">
      <v-btn
  
  v-for="item in menuItems"
  :key="item.text"
  class="mx-2 custom-menu-btn"
  text
>
  <router-link 
    :to="item.to" 
    class="router-link-no-underline w-100 h-100 d-flex align-center justify-center">
    {{ item.text }}
  </router-link>
</v-btn>

    </div>

    <!-- Botão Hamburguer para dispositivos móveis -->
    <v-btn
      icon
      @click="drawer = !drawer"
      class="d-lg-none"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    
  </v-app-bar>
  <!-- Navigation Drawer (Menu Mobile) -->
  <v-navigation-drawer
      v-model="drawer"
      temporary
      class="d-lg-none custom-drawer"
      app
    >
      <v-list class="pa-4">
        <v-list-item
  v-for="item in menuItems"
  :key="item.text"
  link
  @click="fecharDrawer"
  class="d-flex justify-center"
>
  <router-link 
    :to="item.to" 
    class="router-link-no-underline w-100 h-100 d-flex align-center">
    {{ item.text }}
  </router-link>
</v-list-item>

      </v-list>
    </v-navigation-drawer>
</template>

<script>

import formOrcamento from './Cadastros/formOrcamento.vue';
export default {
  components:{
    formOrcamento,
  },
  data() {
    return {
      drawer: false,
      menuItems: [
        { text: "Home", to: "/" },
        { text: "À Empresa", to: "/sobre" },
        { text: "Nossos Serviços", to: "/servicos" },
        { text: "Contatos", to: "/contatos" },
      ],
    };
  },
  methods: {
    abrirOrcamento() {
      console.log("Abrir orçamento online");
    },
    fecharDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
/* Barra de Navegação com Gradiente de Vermelho e Cinza, com Opacidade */
.custom-app-bar {
  background: linear-gradient(
    to right,
    rgba(51, 51, 51, 0.178), /* Cinza com 90% de opacidade */
    rgba(32, 32, 32, 0.9)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
  backdrop-filter: blur(10px); /* Efeito de desfoque opcional */
}

/* Botões do Menu */
.custom-menu-btn {
  
  color: rgba(255, 255, 255, 0.9) !important; /* Branco quase sólido */
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  position: relative;
  z-index: 10;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.custom-menu-btn:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Destaque ao passar o mouse */
  transform: scale(1.05);
}

/* Botão de Orçamento */


/* Navigation Drawer (Menu Mobile) */
.custom-drawer {
  background: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0.438), /* Cinza com 90% de opacidade */
    rgba(32, 32, 32, 0.9)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
}

/* Links do Menu */
.router-link-no-underline {
  text-decoration: none;
  display: block; /* Importante para garantir que o link ocupe toda a área */
  width: 100%; /* Força o link a ocupar toda a largura disponível */
  height: 100%; /* Garante altura total dentro do botão */
  color: inherit; /* Herdar cor do botão */
}

.v-list-item {
  margin: 10px 0;
}

.v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Efeito hover no menu */
  border-radius: 5px;
}
</style>
