<template>
  <v-footer>
    <v-container>
      <v-row align="center">
        <v-col cols="12" md="4" class="text-center">
          <router-link to="/" class="router-link-no-underline">
            <v-avatar rounded="0">
              <v-img cover src="../assets/LOGO.png" alt="Logo"></v-img>
            </v-avatar>
          </router-link>
        </v-col>

        <v-col cols="12" md="4" class="text-center">
          <p class="font-weight-bold">Acompanhe a JE nas Redes Sociais</p>
        </v-col>

        <v-col cols="12" md="4" class="text-center">
          <v-btn
            v-for="(rede, i) in redesSociais"
            :key="i"
            :icon="true"
            class="ma-1"
            @click="abrirLink(rede.link)"
            :color="rede.color"
          >
            <v-icon>{{ rede.icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      
      <v-divider></v-divider>
      <v-row class="mt-2">
        <v-col cols="12" md="3">
          <h4 class="footer-title">Nossos Serviços</h4>
          <v-list>
            <v-list-item
              v-for="(servico, i) in servicos"
              :key="i"
              class="mt-n6"
            >
              <router-link to="/servicos" class="router-link-no-underline">
                {{ servico }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="3">
          <h4 class="footer-title">Institucional</h4>
          <v-list dense>
            <v-list-item
              v-for="(item, i) in institucional"
              :key="i"
              class="mt-n6"
            >
              <router-link to="/sobre" class="router-link-no-underline">
                {{ item }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="2">
          <h4 class="footer-title">Contato</h4>
          <v-list dense>
            <v-list-item
              v-for="(contato, i) in contatos"
              :key="i"
              class="mt-n6"
            >
              <router-link to="/contatos" class="router-link-no-underline">
                {{ contato }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="2">
          <h4 class="footer-title">Solicite seu Orçamento</h4>
          <p class="font-w">(11) 97391-9848 ou 97246-8550</p>
        </v-col>

        <v-col cols="12" md="2">
          <h4 class="footer-title">Endereço da Sede</h4>
          <p class="font-w">São Caetano do Sul - SP</p>
          <p class="font-w">R. Marina Jacomini, 29</p>
          <p class="font-w">Bairro Santa Paula, 09541-360</p>
        </v-col>

      </v-row>

    </v-container>
  
  </v-footer>

</template>

<script>
export default {
  data() {
    return {
      redesSociais: [
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/sconstrutora.je/', color: 'blue darken-2' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/sconstrutora.je/', color: 'purple darken-2' },
        { icon: 'mdi-whatsapp', link: 'https://wa.me/5511972468550', color: 'green darken-3' },
      ],
      servicos: [
        'Revestimentos', 'Pisos', 'Pintura', 'Hidraulica', 'Elétrica', 
        'Impermeabilizações', 'Gesso/Drywall', 'Portas/Janelas', 
        'Alvenaria', 'Estrutural', 'Telhado',
      ],
      institucional: [
        'Quem Somos', 'Missão, Visão e Valores', 'Compromisso com a Qualidade', 
        'Compromisso com o Cliente', 'Parcerias/Colaborações', 'Filosofia de Trabalho',
      ],
      contatos: [
        'Trabalhe Conosco', 'Fale Conosco', 'Perguntas frequentes',
      ],
    };
  },
  methods: {
    abrirLink(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.router-link-no-underline {
  text-decoration: none !important;
  color: inherit;
}

.footer-title {
  margin-left: 10px;
  font-size: 1.2rem;
  color: #d32f2f; /* Vermelho escuro */
  font-weight: bold;
  font-size:16px;
  margin-bottom: 1rem;
}

.v-btn {
  border-radius: 50%;
}

.v-footer {
  padding: 24px 0;
  color: black;
  }

.v-list-item, .font-w {
  margin-bottom: 0.5rem;
  font-size: 12px;
}
</style>
