<template>
  <slideHistoria class="mt-4" />
  <v-row>
      <v-col cols="12" class="mt-6">
        <MissaoValores />
      </v-col>
    </v-row>

  <CompromissoClientes class="mt-2"/>

  <v-row>

<v-col cols="12" class="mt-2">
  <ParceriasColaboracoes /> 
</v-col>
</v-row>

  <slideQualidade class="mt-2" />
  
  <v-row>
      <v-col cols="12" class="mt-2">
        <FilosofiaTrabalho />
      </v-col>
    </v-row>
</template>
<script>

import CompromissoClientes from '@/components/Sobre/CompromissoClientes.vue';
import FilosofiaTrabalho from '@/components/Sobre/FilosofiaTrabalho.vue';
import MissaoValores from '@/components/Sobre/MissaoValores.vue';
import ParceriasColaboracoes from '@/components/Sobre/ParceriasColaboracoes.vue';
import slideHistoria from '@/components/Sobre/slideHistoria.vue';
import slideQualidade from '@/components/Sobre/slideQualidade.vue';

export default {
  metaInfo() {
    return {
      title: "Sobre Nós - JE Construtora",
      meta: [
        {
          name: "description",
          content:
            "Saiba mais sobre a JE Construtora, nossa missão, valores e história no mercado da construção civil.",
        },
        {
          name: "keywords",
          content: "história, missão, construtora, engenharia, JE Construtora",
        },
      ],
    };
  },
  components: {
    slideHistoria,
    slideQualidade,
    ParceriasColaboracoes,
    MissaoValores,
    FilosofiaTrabalho,
    CompromissoClientes
  },
};
</script>
<style scoped>
/* Estilos para garantir margens e espaçamento apropriados */

.py-5 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

/* Ajustes específicos para componentes importados, se necessário */

/* SlideDepoimentos */
.slide-depoimentos {
  padding: 20px;
  background-color: #f5f5f5; /* Exemplo de ajuste de background */
}

/* PacotesPromo */
.pacotes-promo {
  margin-top: 20px;
  border: 1px solid #ddd; /* Exemplo de borda */
}

/* BannerHome */
.banner-home {
  text-align: center;
  margin-bottom: 20px;
}

/* VamosConversar */
.vamos-conversar {
  padding: 10px;
  background-color: #e0e0e0; /* Exemplo de ajuste de background */
}

/* ProcessoOrcamento */
.processo-orcamento {
  margin-top: 30px;
  padding: 15px;
  background-color: #fafafa; /* Exemplo de ajuste de background */
}
</style>
