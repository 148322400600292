import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://prev-construtora-bff6fa01a3b5.herokuapp.com',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    console.error('Erro na API:', error);
    return Promise.reject(error);
  }
);

export default apiClient;
