<template>
  <v-card outlined class="pa-8 mt-4 hover-card">
    <v-row class="pa-8">
      <!-- Título Principal e Introdução -->
      <v-col cols="12">
        <div class="text-h4 text-center mb-4 styled-title" aria-label="Título: História da Empresa">
          História da Empresa
        </div>
        <p class="text-justify styled-paragraph">
          A história da JE CONSTRUTORA é uma jornada de determinação, inovação e sucesso. Convidamos você a fazer parte desta história, confiando em nós para atender às suas necessidades de construção e reforma. Juntos, podemos criar um futuro construído sobre uma base sólida de compromisso e excelência.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <!-- Imagem -->
      <v-col cols="12" md="5" class="mb-4">
        <div v-if="ultimoslideS.imagensSlides.length">
          <v-carousel cycle height="420" hide-delimiters :show-arrows="false" class="carousel-container">
            <v-carousel-item v-for="(img, i) in ultimoslideS.imagensSlides" :key="i">
              <div><v-img
                :src="obterUrlImagem(img)"
                contain
                :alt="`Imagem ${i + 1}`"
                class="carousel-image"
              ></v-img></div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div v-else>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <p class="text-center mt-2 styled-placeholder">Carregando imagens...</p>
        </div>
      </v-col>

      <!-- Texto -->
      <v-col cols="12" md="7">
        <v-card-text>
          <div class="text-h6 mb-2 mt-n2 styled-subtitle">Crescimento e Desenvolvimento</div>
          <p class="text-justify styled-paragraph">
            Nos primeiros anos, concentramos nossos esforços em estabelecer nossa presença no mercado local. Com dedicação incansável, conquistamos nossa primeira grande obra e expandimos nossas capacidades em termos de serviços oferecidos.
          </p>

          <div class="text-h6 mb-2 mt-4 styled-subtitle">Adaptação e Inovação</div>
          <p class="text-justify styled-paragraph">
            A JE CONSTRUTORA sempre se esforçou para se manter à frente das tendências do setor. Abraçamos novas tecnologias e práticas sustentáveis para oferecer serviços de excelência.
          </p>

          <div class="text-h6 mb-2 mt-4 styled-subtitle">Nossa Visão Futura</div>
          <p class="text-justify styled-paragraph">
            Nossa visão é continuar como líderes em construção e reforma, estabelecendo relacionamentos duradouros e entregando serviços de qualidade à nossa comunidade.
          </p>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      ultimoslideS: {
        imagensSlides: []
      },
    };
  },
  mounted() {
    this.carregarultimoslideS();
  },
  methods: {
    async carregarultimoslideS() {
      try {
        const response = await axios.get('https://prev-construtora-bff6fa01a3b5.herokuapp.com/SlideSobre');
        if (response.data && response.data.length > 0) {
          this.ultimoslideS = response.data[response.data.length - 1];
        }
      } catch (error) {
        console.error('Erro ao carregar o último slide:', error.message);
      }
    },
    obterUrlImagem(img) {
      if (typeof img === 'string' && img.startsWith("https://")) {
        return img;
      }
      // Caso a imagem não seja uma URL completa, construímos a URL base
      return img ? `https://para-construcoes.s3.us-west-2.amazonaws.com/${img}` : '';
    },
  }
};
</script>

<style scoped>
/* Estilização Geral */
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
  
}
.hover-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Títulos */
.styled-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}

.styled-subtitle {
  color: #424242;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

/* Texto */
.styled-paragraph {
  color: #212121;
  line-height: 1.6;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.styled-placeholder {
  color: #9e9e9e;
  font-style: italic;
}

/* Carrossel */
.carousel-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 15px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.carousel-image:hover {
  transform: scale(1.05);
}

/* Margens e Padding */
.mb-2 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mt-4 {
  margin-top: 24px !important;
}
</style>
