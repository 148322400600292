<template>
  <v-card outlined class="pa-2 mt-4 hover-card" :height="600">
    <!-- Título Principal -->
    <div class="text-h4 mb-2 styled-title">Meios de Comunicação</div>

    <!-- Informações de Contato -->
    <v-row class="text-center">
      <!-- Horário de Atendimento -->
      <v-col cols="6" md="6" class="mt-4">
        <v-card outlined class="pa-4 hover-card">
          <v-card-title class="title-highlight">Horário Atendimento</v-card-title>
          <v-card-text>
            <p><span class="font-weight-bold">Seg-Sex:</span> 08h às 18h</p>
            <p><span class="font-weight-bold">Sáb-Dom:</span> 08h às 12h</p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Números de Telefone -->
      <v-col cols="6" md="6" class="mt-4">
        <v-card outlined class="pa-4 hover-card">
          <v-card-title class="title-highlight">Telefones</v-card-title>
          <v-card-text>
            <p><span class="font-weight-bold">Ouvidoria:</span> (11) 97391-9848</p>
            <p><span class="font-weight-bold">Comercial:</span> (11) 97246-8550</p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Endereço Físico -->
      <v-col cols="12" md="6" class="mt-4">
        <v-card outlined class="pa-4 hover-card">
          <v-card-title class="title-highlight">Endereço</v-card-title>
          <v-card-text>
            <p>Rua Marina Jacomini, 29</p>
            <p>CEP 09541-360, Santa Paula, São Caetano do Sul - SP</p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- E-mails -->
      <v-col cols="12" md="6" class="mt-4">
        <v-card outlined class="pa-4 hover-card">
          <v-card-title class="title-highlight">E-mail:</v-card-title>
          <v-card-text>
            <p> jeservicos9@gmail.com</p>
              <br>
            <p> admconstrutoraje@gmail.com</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Redes Sociais -->
    <v-row class="text-center mt-4">
      <v-col cols="12">
        <div class="title-highlight mb-4">Nossas Redes</div>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn variant="plain" icon @click="abrirLink('https://www.facebook.com/')">
              <v-avatar size="80" class="avatar-icon">
                <v-icon color="indigo darken-4">mdi-facebook</v-icon>
              </v-avatar>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn variant="plain" icon @click="abrirLink('https://www.instagram.com/')">
              <v-avatar size="80" class="avatar-icon">
                <v-icon size="large" color="pink darken-1">mdi-instagram</v-icon>
              </v-avatar>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn variant="plain" icon @click="abrirLink('https://api.whatsapp.com/')">
              <v-avatar size="80" class="avatar-icon">
                <v-icon color="green darken-4">mdi-whatsapp</v-icon>
              </v-avatar>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  methods: {
    abrirLink(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style scoped>
.avatar-icon {
  background-color: #47474728; /* Fundo verde claro */
  color: #770901c0; /* Ícone verde escuro */
}
.styled-title {
  text-align: center;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
.text-center {
  font-size: 14px;
  color: #212121;
  line-height: 1.4;
}
.title-highlight {
  color: #9b0303dc; /* Vermelho Escuro */
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para profundidade */
  letter-spacing: 1px; /* Aumenta o espaçamento entre as letras para melhorar a legibilidade */
}
.mb-4 {
  margin-bottom: 16px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.pa-4 {
  padding: 16px !important;
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87) /* Cor branca */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.v-btn {
  margin: 0 8px;
}

/* Ajustes de layout para dispositivos móveis */
@media (max-width: 600px) {
  .styled-title {
    font-size: 18px;
  }
  .title-highlight {
    font-size: 16px;
  }
  .text-center {
    font-size: 12px;
  }
  .v-card-text p {
    font-size: 12px;
  }
}
</style>
