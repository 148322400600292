import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia' // Importa o Pinia

loadFonts()

const app = createApp(App)
const head = createHead()
const pinia = createPinia() // Cria a instância do Pinia

app.use(head)
app.use(router)
app.use(vuetify)
app.use(pinia) // Usa o Pinia na aplicação
app.mount('#app')
