<template>
  <div class="pa-6">
    <ListagemServicos/>
  </div>
 
</template>
<script>
import ListagemServicos from '@/components/Servicos/ListagemServicos.vue';

export default {
  metaInfo() {
    return {
      title: "Nossos Serviços - JE Construtora",
      meta: [
        {
          name: "description",
          content:
            "Conheça os serviços da JE Construtora: construção civil, reformas residenciais e comerciais, e projetos personalizados.",
        },
        {
          name: "keywords",
          content: "serviços, construção, reformas, projetos personalizados",
        },
      ],
    };
  },
  components: {
    ListagemServicos,
  }
};
</script>
<style scoped>
/* Estilos para garantir margens e espaçamento apropriados */

.py-5 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

/* Ajustes específicos para componentes importados, se necessário */

/* PacoteServicos */
.pacote-servicos {
  padding: 20px;
  background-color: #f5f5f5; /* Exemplo de ajuste de background */
}

/* ListagemServicos */
.listagem-servicos {
  margin-top: 20px;
  border: 1px solid #ddd; /* Exemplo de borda */
}
</style>
