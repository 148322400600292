<template>
  <v-card class="pa-4 mt-2 hover-card" outlined>
    <v-row>
      <!-- Título Principal -->
      <v-col cols="12">
        <div class="text-h4 text-center mb-4 styled-title">Compromisso com os Clientes</div>
        <p class="text-justify mb-4">
          Na JE CONSTRUTORA, nosso compromisso com o cliente é a base de tudo o que fazemos. Reconhecemos que nossos clientes são a razão de nosso sucesso e, portanto, colocamos suas necessidades e satisfação no centro de nossas operações.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <!-- Parallax com Call-to-Action -->
      <v-col md="6" class="mb-4">
        <v-parallax
          src="@/assets/Qualidade.webp"
          height="500"
          class="parallax-container custom-parallax"
        >
          <div class="d-flex flex-column fill-height justify-center align-center text-white parallax-content">
            <h1 class="text-h5 font-weight mb-3">Vamos conversar sobre sua Construção ou Reforma?</h1>
            <h4 class="subheading mb-3">Fale com nossos especialistas agora!</h4>
            <v-btn size="large" class="btn-specialist mt-3" rounded="lg">Fale com um Especialista</v-btn>
          </div>
        </v-parallax>
      </v-col>

      <!-- Seção de Detalhes -->
      <v-col cols="12" md="6">
        <div>
          <v-expand-transition>
            <v-container>
              <v-row v-for="(detalhe, index) in detalhes" :key="index">
                <v-col cols="12" class="mb-3">
                  <div class="text-h6 subtitle">{{ detalhe.titulo }}</div>
                  <p class="text-justify">{{ detalhe.descricao }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
        </div>
      </v-col>
    </v-row>

    <!-- Texto Final -->
    <v-row>
      <v-col cols="12">
        <p class="text-justify mt-n6 final-text">
          Na JE CONSTRUTORA, construímos relacionamentos baseados em confiança e respeito. Cada cliente é visto como um parceiro em potencial, e estamos comprometidos em garantir excelência em todas as etapas do processo.
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      detalhes: [
        {
          titulo: "1. Satisfação do Cliente em Primeiro Lugar",
          descricao: "Garantimos que você ficará satisfeito com o resultado final, com cada detalhe pensado para atender às suas expectativas."
        },
        {
          titulo: "2. Comunicação Transparente",
          descricao: "Desde o orçamento inicial até a conclusão, mantemos você informado de todas as etapas."
        },
        {
          titulo: "3. Cumprimento de Prazos",
          descricao: "Prazos são compromissos. Trabalhamos para entregar cada projeto dentro do tempo estipulado."
        },
        {
          titulo: "4. Transparência nos Orçamentos",
          descricao: "Nossos orçamentos são claros, detalhados e sem surpresas desagradáveis."
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Título Principal */
.styled-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
/* Subtítulos e Descrições */
.subtitle {
  color: #424242;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.text-justify {
  color: #212121;
  line-height: 1.6;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

/* Texto Final */
.final-text {
  color: #424242;
  margin-top: 16px;
  font-style: italic;
}

/* Parallax e Call-to-Action */
.custom-parallax {
  background-attachment: fixed;
  border-radius: 8px;
}

.parallax-content {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 20px;
}

.btn-specialist {
  background-color: #ff5252;
  color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.btn-specialist:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

/* Animações */
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}
</style>
