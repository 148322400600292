<template>
	
  <p class="styled-title">Conheça nosso Catálogo</p>

  <v-row>
    <!-- Itera sobre os pacotes promocionais -->
     
    <v-col
      
      cols="12"
      md="3"
      v-for="(pacote, pacoteIdx) in portfolios"
      :key="pacote._id"
    >
    
      <v-card class=" pa-1 hover-card">
        <p class="title-highlight">{{ pacote.titulo }}</p>

        <!-- Skeleton Loader para imagens -->
        <v-skeleton-loader
          v-if="!imagensCarregadas[pacoteIdx]"
          type="image"
        ></v-skeleton-loader>

        <!-- Carrossel de imagens -->
        <v-carousel
          v-else
          cycle
          height="300"
          :continuous="true"
          :show-arrows="false"
          delimiter-icon="mdi-square"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(img, idx) in pacote.imagensServicos || []"
            :key="idx"
          >
            <div>
              <v-img
                :src="obterUrlImagem(img)"
                
                :alt="`Imagem ${idx + 1}`"
                class="carousel-image"
                @click="abrirImagem(pacoteIdx, idx)"
              ></v-img>
            </div>
          </v-carousel-item>
        </v-carousel>

        <!-- Verificação adicional para garantir que pacote.descricoes seja um array -->
        <v-carousel
          class="description-text"
          v-if="pacote.descricoes && pacote.descricoes.length"
          cycle
          height="20"
          :continuous="true"
          :show-arrows="false"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(desc, descIdx) in pacote.descricoes"
            :key="descIdx"
          >
            {{ desc }}
          </v-carousel-item>
        </v-carousel>

        <v-card-actions class="mt-4">
          <v-btn
          class="rounded-lg custom-btn w-100 mt-n12"
          @click="abrirWhats"
          aria-label="Abrir WhatsApp para orçamento"
        >
          <v-icon left>mdi-whatsapp</v-icon>
          Orçamento Online
        </v-btn>

        </v-card-actions>
      </v-card>
    </v-col>

    <!-- Diálogo para exibir imagens ampliadas -->
    <v-dialog v-model="dialogImagem" max-width="800px">
      <v-carousel
        v-if="imagensDialog.length"
        v-model="currentImage"
        height="auto"
        show-arrows
        delimiter-icon="mdi-square"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(img, idx) in imagensDialog"
          :key="idx"
        >
          <v-img :src="img" alt="Imagem Ampliada" contain>
            <v-btn
              icon="mdi-close"
              @click="fecharDialogImagem"
            ></v-btn>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const portfolios = ref([]);
    const dialogImagem = ref(false);
    const currentImage = ref(0);
    const imagensDialog = ref([]);
    const imagensCarregadas = ref([]);

    const carregarPortfolios = async () => {
      try {
        const response = await axios.get(
          "https://prev-construtora-bff6fa01a3b5.herokuapp.com/portfolios"
        );
        console.log("Dados carregados:", response.data); // Verifique se os dados são carregados corretamente
        portfolios.value = response.data.map((portfolio) => ({
          ...portfolio,
          titulos: portfolio.titulos || [], // Garante que titulos esteja presente
          descricoes: portfolio.titulos
            ? portfolio.titulos.flatMap((titulo) => titulo.descricoes)
            : [], // Extrai descrições
        }));

        // Inicializa o estado de carregamento das imagens
        imagensCarregadas.value = portfolios.value.map(() => false);

        // Pré-carregar imagens
        preCarregarImagens();
      } catch (error) {
        console.error("Erro ao carregar portfolios:", error.message);
      }
    };

    const obterUrlImagem = (img) => {
      if (typeof img === "string" && img.startsWith("https://")) {
        return img;
      }
      // Caso a imagem não seja uma URL completa, construímos a URL base
      return img
        ? `https://para-construcoes.s3.us-west-2.amazonaws.com/${img}`
        : "";
    };

    const abrirImagem = (pacoteIdx, imgIdx) => {
      const portfolio = portfolios.value[pacoteIdx];
      imagensDialog.value = portfolio.imagensServicos.map((img) => {
        console.log("Imagem:", img); // Adicione este log
        return obterUrlImagem(img);
      });
      currentImage.value = imgIdx;
      dialogImagem.value = true;
    };

    const fecharDialogImagem = () => {
      dialogImagem.value = false;
      imagensDialog.value = [];
      currentImage.value = 0;
    };

    const preCarregarImagens = () => {
      portfolios.value.forEach((portfolio, index) => {
        Promise.all(
          (portfolio.imagensServicos || []).map((img) => {
            return new Promise((resolve) => {
              const imgElement = new Image();
              imgElement.src = obterUrlImagem(img);
              imgElement.onload = resolve;
            });
          })
        ).then(() => {
          imagensCarregadas.value[index] = true;
        });
      });
    };

    const abrirWhats = () => {
  // Dispara o evento de conversão
  // Se quiser redirecionar via gtag_report_conversion, passe a URL como parâmetro
  // Se não precisar redirecionar, pode chamar sem argumento
    window.gtag_report_conversion();

  try {
    window.open(
      "https://wa.me/5511972468550?text=Olá,%20gostaria%20de%20um%20orçamento!",
      "_blank"
    );
  } catch (error) {
    alert(
      "Não foi possível abrir o WhatsApp. Copie o link: https://wa.me/5511972468550"
    );
  }
};


    onMounted(() => {
      carregarPortfolios();
    });

    return {
      portfolios,
      dialogImagem,
      currentImage,
      imagensDialog,
      imagensCarregadas,
      carregarPortfolios,
      obterUrlImagem,
      abrirImagem,
      fecharDialogImagem,
      abrirWhats,
    };
  },
};
</script>

<style scoped>
.carousel-image {
             /* Preenche a altura do contêiner */
  object-fit: cover;        /* Garante corte para preencher todo o espaço */
  object-position: center;  /* Centraliza a imagem no contêiner */
  display: block;           /* Evita espaços extras ao redor da imagem */
}

.hover-card {
  margin-top: -25px;
  height: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
  color: white;
}
.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.2);
}
.custom-btn {
  background-color: rgba(211, 47, 47, 0.9) !important; /* Vermelho mais vivo com 90% de opacidade */
  color: white !important;
  font-weight: bold;
}

.custom-btn:hover {
  background-color: rgba(198, 40, 40, 0.9) !important; /* Vermelho mais intenso com 90% de opacidade */
}

/* Estilo do título com destaque */
.title-highlight {
  text-align: center;
  color: #fff8f8; /* Vermelho Escuro */
  font-size: 16px;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para profundidade */
  letter-spacing: 1px; /* Aumenta o espaçamento entre as letras para melhorar a legibilidade */
}
.styled-title {
  margin: 20px 0 50px ;
  text-align: center;
  font-size: 18px;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
/* Ajuste de estilo do texto das descrições */
.description-text {
  text-align: center;
  margin: -20px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.637)    /* Vermelho Escuro com 90% de opacidade */
  );
  color:#9b0303dc;
}

/* Placeholder de carregamento */
.v-skeleton-loader {
  background: #e0e0e0;
  border-radius: 8px;
  height: 100%;
}

/* Estilos do diálogo de imagem */
.v-dialog .v-img {
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

/* Melhorando o layout para telas pequenas */
@media (max-width: 300px) {
  .hover-card {
    height: auto;
  }
  .v-card-title {
    font-size: 18px;
  }
  .carousel-image {
    height: 200px; /* Ajuste da altura da imagem no carousel */
  }
}
</style>
