<template>
  <v-card elevation="10" class="pa-2 mt-n2 hover-card" :height="625">
    <!-- Título e Introdução -->
    <div class="text-h4 mb-2 title-highlight">Contato</div>
        <p class="text-center mb-4">
          Utilize o formulário abaixo para entrar em contato conosco sobre seu projeto. Estamos prontos para ajudar a transformar sua visão em realidade.
        </p>

    <!-- Formulário de Contato -->
    
        <FormContato/>
    <!-- Nota Final -->
    <v-row>
      <v-col cols="12">
        <p class="text-center mt-n2">
          Preencha o formulário e clique em "Enviar". Nossa equipe entrará em contato o mais rápido possível para discutir os detalhes do seu projeto e criar um orçamento personalizado.
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import FormContato from '../Cadastros/formContato.vue';

export default{
  components: {FormContato,
    
  }
}

</script>
<style scoped>
.mb-4 {
  margin-bottom: 16px !important;
}

.mt-6 {
  margin-top: 48px !important;
}
.title-highlight {
  text-align: center;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
.text-center {
  font-size: 14px;
  color: #212121;
  line-height: 1.4;
 
}
.text-justify {
  text-align: justify;
  color: #212121; /* Preto */
}

.pa-6 {
  padding: 24px !important;
}

.v-btn {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.v-btn:hover {
  background-color: #0056b3; /* Mudança de cor ao passar o mouse */
  color: white; /* Mudança de cor do texto ao passar o mouse */
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}
</style>
