<template>
  <v-card class="pa-4 mt-2 hover-card" outlined>
    <v-row>
      <!-- Título Principal -->
      <v-col cols="12">
        <div class="text-h4 text-center mb-4 primary-title">Parcerias e Colaborações</div>
        <p class="text-justify mb-4">
          Na JE CONSTRUTORA, acreditamos que parcerias sólidas e colaborações estratégicas são fundamentais para o nosso sucesso contínuo e para oferecer aos nossos clientes os melhores serviços possíveis. Nossa abordagem de trabalho envolve colaborar com empresas e organizações que compartilham nossa visão de qualidade, inovação e compromisso com a satisfação do cliente. Aqui estão algumas das nossas principais parcerias e colaborações:
        </p>
      </v-col>
    </v-row>

    <!-- Tópicos de Parcerias -->
    <v-row>
      <v-col cols="12" md="6" class="mb-4" v-for="(parceria, index) in parcerias" :key="index">
        <v-card class="pa-4 text-center hover-card bg-grey-lighten-4" outlined>
          <v-avatar size="80" class="mb-3 mx-auto avatar-icon">
            <v-icon size="large">{{ parceria.icon }}</v-icon>
          </v-avatar>
          <h3 class="font-weight-bold mb-2">{{ parceria.titulo }}</h3>
          <p class="text-justify">{{ parceria.descricao }}</p>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  data() {
    return {
      parcerias: [
        {
          icon: "mdi-package-variant-closed",
          titulo: "Fornecedores de Materiais de Construção de Confiança",
          descricao:
            "Mantemos relacionamentos de longo prazo com fornecedores de materiais de construção que são conhecidos por sua qualidade e confiabilidade. Isso nos permite oferecer aos nossos clientes materiais de alta qualidade em todos os projetos."
        },
        {
          icon: "mdi-hammer-screwdriver",
          titulo: "Parceiros de Serviços Complementares",
          descricao:
            "Trabalhamos em estreita colaboração com empresas que oferecem serviços complementares aos nossos, como serviços elétricos, hidráulicos e de revestimento. Essas parcerias nos permitem fornecer soluções abrangentes para nossos clientes."
        },
        {
          icon: "mdi-account-group",
          titulo: "Parcerias na Comunidade Local",
          descricao:
            "Valorizamos nossa comunidade e estamos comprometidos em fazer a diferença. Colaboramos com organizações locais em projetos de responsabilidade social e contribuímos para melhorar o ambiente onde vivemos e trabalhamos."
        },
        {
          icon: "mdi-handshake-outline",
          titulo: "Alianças Estratégicas",
          descricao:
            "Buscamos alianças estratégicas com outras empresas do setor de construção e serviços para melhorar nossa eficiência operacional e expandir nossa capacidade de atender a projetos complexos."
        }
      ]
    };
  },

};
</script>

<style scoped>
.primary-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;

}

.mb-4 {
  margin-bottom: 16px !important;
}

.text-justify {
  text-align: justify;
  color: #424242;
}

.font-weight-bold {
  color: #424242;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.avatar-icon {
  background-color: #47474728; /* Fundo verde claro */
  color: #770901c0; /* Ícone verde escuro */
}

@media (max-width: 768px) {
  .primary-title {
    font-size: 1.6rem;
  }

  .hover-partner {
    margin-bottom: 16px;
  }
}
</style>
