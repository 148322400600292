<template>
  <v-card class="pa-4 mt-2 hover-card" outlined>
      <v-row>
        <!-- Título Principal -->
        <v-col cols="12">
          <transition name="fade-slide">
            <div class="text-h4 text-center mb-4 styled-title">
              Filosofia de Trabalho
            </div>
          </transition>
          <p class="text-justify mb-4">
            Na JE CONSTRUTORA, nossa filosofia de trabalho é o alicerce que sustenta todas as nossas operações e orienta cada decisão que tomamos. Somos apaixonados pelo que fazemos e estamos comprometidos em fornecer serviços excepcionais de construção e reforma. Nossa filosofia é moldada por três princípios fundamentais que consideramos essenciais para o nosso sucesso:
          </p>
        </v-col>
      </v-row>

      <!-- Tópicos -->
      <v-row>
        <v-col
          v-for="(principle, index) in principles"
          :key="index"
          cols="12"
          md="4"
          class="mb-4"
        >
          <v-card
            class="pa-4 text-center hover-card bg-card-gradient"
            outlined
            elevation="10"
          >
            <v-avatar size="80" class="mb-3 mx-auto gradient-avatar">
              <v-icon size="large" class="white--text">{{ principle.icon }}</v-icon>
            </v-avatar>
            <h3 class="font-weight-bold mb-2 text-primary">{{ principle.title }}</h3>
            <p class="text-justify">{{ principle.description }}</p>
          </v-card>
        </v-col>
      </v-row>

      <!-- Conclusão -->
      <v-row>
        <v-col cols="12">
          <p class="text-justify mb-4">
            Além desses princípios fundamentais, nossa filosofia de trabalho também inclui um foco contínuo no aprendizado e na melhoria. Buscamos constantemente inovações na indústria da construção e investimos no desenvolvimento de nossa equipe para garantir que estejamos sempre à frente no que diz respeito às melhores práticas e tecnologias.
          </p>
          <p class="text-justify">
            Na JE CONSTRUTORA, nossa filosofia de trabalho é mais do que palavras em uma página; é a força motriz por trás de nossa dedicação incansável à qualidade, transparência e excelência em serviços de construção. Estamos empenhados em construir não apenas edifícios, mas também relacionamentos sólidos com nossos clientes, baseados na confiança e no respeito mútuo.
          </p>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {
  data() {
    return {
      principles: [
        {
          icon: 'mdi-check-circle-outline',
          title: '1. Excelência em Qualidade',
          description:
            'Buscamos a excelência em tudo o que fazemos. Desde a seleção dos materiais até a execução de projetos, nosso compromisso inabalável com a qualidade garante que cada projeto seja executado com os mais altos padrões.'
        },
        {
          icon: 'mdi-comment-check-outline',
          title: '2. Transparência e Comunicação Aberta',
          description:
            'Acreditamos que a comunicação aberta e transparente é a chave para um relacionamento bem-sucedido com nossos clientes. Estamos comprometidos em manter nossos clientes informados em cada etapa do projeto.'
        },
        {
          icon: 'mdi-calendar-clock',
          title: '3. Compromisso com o Prazo e o Orçamento',
          description:
            'Entendemos a importância de cumprir prazos e manter orçamentos. Nosso compromisso com a gestão eficiente de projetos significa que nos esforçamos para entregar no prazo e dentro do orçamento acordado.'
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Animações */
@keyframes fadeSlide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-slide-enter-active {
  animation: fadeSlide 0.8s ease-in-out;
}

/* Paleta de cores */
.bg-card-gradient {
  background: linear-gradient(145deg, #283593, #1565c0);
  color: white;
}

.gradient-avatar {
  background-color: #47474728; /* Fundo verde claro */
  color: #770901c0; /* Ícone verde escuro */}

.text-primary {
  color: #b71c1cad !important;
}

/* Título estilizado */
.styled-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}

/* Interatividade com hover */
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
}

.hover-card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

/* Tipografia e ajustes gerais */
.text-justify {
  font-size: 16px;
  color: #212121; /* Cor escura para contraste */
  margin-bottom: 12px;
  line-height: 1.6; /* Aumenta o espaçamento entre linhas para melhorar a leitura */
  /* Texto mais forte para as descrições */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif; 
}

.font-weight-bold {
  font-weight: bold;
}
</style>
