<template>
  <v-card class="pa-4 mt-n4 styled-card" outlined>
    <!-- Título Principal com transição de fade-in e slide-down -->
    <transition name="slide-fade">
      <div class="text-h4 text-center mb-5 styled-title">Missão, Visão e Valores</div>
    </transition>

    <v-row class="text-center mt-4">
      <!-- Missão -->
      <transition name="zoom-in">
        <v-col cols="12" md="4" class="d-flex justify-center mb-4 fade-in">
          <v-card class="mx-auto mission-card styled-card" max-width="400" elevation="12">
            <v-avatar size="80" class="mt-3 mx-auto avatar-icon">
              <v-icon size="large">mdi-star</v-icon>
            </v-avatar>
            <v-card-title class="font-weight-bold">Missão</v-card-title>
            <v-card-text class="text-justify">
              Na JE CONSTRUTORA, nossa missão é superar as expectativas dos clientes ao oferecer serviços de construção e reforma de excelência. Comprometemo-nos a construir não apenas estruturas físicas, mas também relações sólidas e duradouras.
            </v-card-text>
          </v-card>
        </v-col>
      </transition>

      <!-- Visão -->
      <transition name="zoom-in">
        <v-col cols="12" md="4" class="d-flex justify-center mb-4 fade-in">
          <v-card class="mx-auto vision-card styled-card" max-width="400" elevation="12">
            <v-avatar size="80" class="mt-3 mx-auto avatar-icon">
              <v-icon size="large">mdi-eye</v-icon>
            </v-avatar>
            <v-card-title class="font-weight-bold">Visão</v-card-title>
            <v-card-text class="text-justify">
              Nossa visão é ser a construtora de escolha, conhecida pela qualidade inigualável, inovação constante e compromisso irrevogável com a satisfação do cliente.
            </v-card-text>
          </v-card>
        </v-col>
      </transition>

      <!-- Valores -->
      <transition name="zoom-in">
        <v-col cols="12" md="4" class="d-flex justify-center mb-4 fade-in">
          <v-card class="mx-auto values-card styled-card" max-width="400" elevation="12">
            <v-avatar size="80" class="mt-3 mx-auto avatar-icon">
              <v-icon size="large">mdi-heart</v-icon>
            </v-avatar>
            <v-card-title class="font-weight-bold">Valores</v-card-title>
            <v-card-text class="text-justify">
              Nossa visão é ser referência no setor da construção, promovendo crescimento sustentável e criando impacto positivo para nossos clientes e comunidades.
            </v-card-text>
          </v-card>
        </v-col>
      </transition>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      txtmsn: [
        { titulo: 'Integridade', conteudo: 'Agimos com honestidade e transparência em todas as nossas interações.' },
        { titulo: 'Excelência', conteudo: 'Buscamos a excelência em cada projeto, focando na qualidade e satisfação do cliente.' },
        { titulo: 'Inovação', conteudo: 'Estamos sempre em busca de novas tecnologias e métodos para aprimorar nossos serviços.' },
        { titulo: 'Sustentabilidade', conteudo: 'Comprometemo-nos a práticas sustentáveis e ao cuidado com o meio ambiente.' },
        { titulo: 'Respeito', conteudo: 'Valorizamos nossos clientes, colaboradores e parceiros, promovendo um ambiente de respeito mútuo.' }
      ]
    };
  }
};
</script>

<style scoped>
/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.slide-fade-enter-active {
  animation: slideFadeIn 1s ease-in-out;
}

@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.zoom-in-enter-active {
  animation: zoomIn 0.8s ease;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Estilos Gerais */
.avatar-icon {
 
  background-color: #47474728; /* Fundo verde claro */
  color: #770901c0; /* Ícone verde escuro */

}

.styled-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87)    /* Vermelho Escuro com 90% de opacidade */
  );
 
}

.styled-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.styled-title {
  color: #b71c1cad;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}

.font-weight-bold {
  color: #424242;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

.text-justify {
  color: #212121;
  line-height: 1.6;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
</style>
