<template>
  <v-row class="mt-2">
      <v-col cols="12" md="6" class="mt-n2">
      <MeiosComunicacao/>
      </v-col>  
      <v-col cols="12" md="6" class="mt-n2">
      <InfoContato/>
      </v-col>
      <v-col cols="12" md="6" class="mt-n2">
        <FormContato/>
      </v-col>
      <v-col cols="12" md="6" class="mt-n2">
        <PerguntasFrequentes/>
      </v-col>
    
      
    </v-row>
    
</template>
<script>
import FormContato from '@/components/Contato/FormContato.vue';
import MeiosComunicacao from '@/components/Contato/MeiosComunicacao.vue';
import InfoContato from '@/components/Contato/InfoContato.vue';
import PerguntasFrequentes from '@/components/Contato/PerguntasFrequentes.vue';

export default {
  metaInfo() {
    return {
      title: "Fale Conosco - JE Construtora",
      meta: [
        {
          name: "description",
          content:
            "Entre em contato com a JE Construtora para solicitar orçamentos e tirar dúvidas sobre nossos serviços.",
        },
        {
          name: "keywords",
          content: "contato, orçamentos, construtora, engenharia civil",
        },
      ],
    };
  },
  components: {
    FormContato,
    MeiosComunicacao,
    InfoContato,
    PerguntasFrequentes,
  }
};
</script>
<style scoped>
/* Estilos para garantir margens e espaçamento apropriados */

.py-5 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}
</style>
