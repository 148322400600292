<template>
  <v-card class="pa-2 hover-card" outlined>
    <v-carousel v-if="ultimasDescricoes.length" cycle hide-delimiters :show-arrows="false" class="full-width">
      <p class="styled-title">
      Depoimentos
     </p>
      <v-carousel-item v-for="(descricao, i) in ultimasDescricoes" :key="i">
        <v-icon >mdi-comment-quote</v-icon>
              {{ descricao }}
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import apiClient from '@/servicos/apiClient';
import { LRUCache } from 'lru-cache';

// Configuração do cache LRU
const cache = new LRUCache({
  max: 50, // Máximo de 50 itens
  ttl: 1000 * 60 * 5, // Cache expira após 5 minutos
});

export default {
  data() {
    return {
      depoimentos: [],
      ultimasDescricoes: [],
    };
  },

  mounted() {
    this.carregarDepoimentos();
  },
  methods: {
    async carregarDepoimentos() {
      const cacheKey = 'depoimentos'; // Chave do cache
      try {
        if (cache.has(cacheKey)) {
          // Se os depoimentos estão no cache, utiliza-os
          const cachedData = cache.get(cacheKey);
          this.processarDepoimentos(cachedData);
        } else {
          // Caso contrário, faz a requisição à API
          const response = await apiClient.get('/depoimentos');
          if (response.data && response.data.length > 0) {
            this.processarDepoimentos(response.data);
            // Armazena os dados no cache
            cache.set(cacheKey, response.data);
          }
        }
      } catch (error) {
        console.error('Erro ao carregar depoimentos:', error.message);
      }
    },
    processarDepoimentos(data) {
      this.depoimentos = data;
      this.ultimasDescricoes = data[data.length - 1]?.depoimentos.flatMap(dep => dep.descricoes) || [];
    },
  },
};
</script>

<style scoped>
/* Estilos otimizados */
.full-width {
  text-align: center;
  font-size:14px;
  font-weight: 500;
  height: 80px;
 
}
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(to right, rgba(223, 223, 223, 0.747), rgba(241, 238, 238, 0.507));
  height: 100px;
  text-align: center;
  font-size:14px;
  font-weight: 500;
  
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  
}
.styled-title {
  margin: 10px 0 10px ;
  text-align: center;
  font-size: 18px;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media (max-width: 600px) {
  .hover-card {
    height: 150px;
  }
  .full-width {
  height: 80px;
 
}
}
</style>
