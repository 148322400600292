<template>
  <v-card outlined class="pa-2 mt-4 hover-card" :height="600">
    <!-- Título Principal -->
    <v-row>
      <v-col cols="12">
        <div class="text-h4 mb-2 styled-title">Nossa Localização</div>
        <p class="text-center mb-2">
          Conecte-se conosco para iniciar seu projeto de construção ou reforma. Estamos prontos para ajudar.
        </p>
      </v-col>
  
      <v-col cols="12" class="mt-2">
        <v-responsive aspect-ratio="16/9">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2503.2806561733287!2d-46.566565623853435!3d-23.61792518646954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5d0f4738232d%3A0xc8f38e36fd8d2166!2sJe%20Silva%20Construtora!5e0!3m2!1spt-BR!2sbr!4v1702299285916!5m2!1spt-BR!2sbr"
            width="100%"
            height="500"
            style="border:0;"
            
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-responsive>
      </v-col>
   
      <v-col cols="12">
        <p class="text-center mt-2 mb-2">
          Ligue, envie um e-mail ou visite-nos. Estamos ansiosos para transformar suas ideias em realidade.
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped>

.mb-4 {
  margin-bottom: 16px !important;
}

.mt-6 {
  margin-top: 48px !important;
}
.styled-title {
  text-align: center;
  color: #770901c0;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
.text-center {
  font-size: 14px;
  color: #212121;
  line-height: 1.4;
 
}
.v-responsive {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
}

iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.9), /* Cinza com 90% de opacidade */
    rgba(255, 255, 255, 0.87) /* Cor branca */
  );
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}


</style>
