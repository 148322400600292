<template>
  
  <v-app class="custom-back">
    <!-- Barra Superior com navegação -->
    <v-main class="mt-n6">
      <BarraSuperior /> 

      <!-- Conteúdo principal do app -->
      <router-view />

      <!-- Botão do WhatsApp para contato -->
      <v-btn
        v-if="mostrarCTA"
        fab
        dark
        color="green"
        class="ma-4 v-whats"
        @click="redirecionarParaWhatsApp"
      >
        <v-icon size="large">mdi-whatsapp</v-icon>
      </v-btn>

      <!-- Rodapé Inferior -->
      <RodapeInferior />
    </v-main>
  </v-app>
</template>
<script>
import BarraSuperior from '@/components/BarraSuperior.vue'
import RodapeInferior from '@/components/RodapeInferior.vue'

export default {
  components: {
    BarraSuperior,
    RodapeInferior,
  },
  name: 'App',
  data() {
    return {
      mostrarCTA: true, // Controle para mostrar/ocultar botão de WhatsApp
    };
  },
  methods: {
    redirecionarParaWhatsApp() {
      // Número de telefone do WhatsApp com mensagem pré-definida
      window.location.href = 'https://api.whatsapp.com/send?phone=5511972468550&text=Vim%20através%20do%20site';
    },
  },
};
</script>
<style scoped>
.custom-back{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: linear-gradient(to right, rgba(158, 157, 158, 0.61), rgba(73, 72, 72, 0.479));
  color: white;
}

/* Personaliza o botão flutuante do WhatsApp */
.v-whats {
  z-index: 0;
  position: fixed;
  bottom: 16px;
  right: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Sombra suave */
}

.v-whats:hover {
  background-color: #4caf50 !important; /* Verde mais claro ao passar o mouse */
}

</style>
